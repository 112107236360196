/* inter-regular - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/inter-v13-latin-regular.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../../fonts/inter-v13-latin-regular.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* inter-500 - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url('../../fonts/inter-v13-latin-500.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../../fonts/inter-v13-latin-500.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* inter-600 - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url('../../fonts/inter-v13-latin-600.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../../fonts/inter-v13-latin-600.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* inter-700 - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url('../../fonts/inter-v13-latin-700.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../../fonts/inter-v13-latin-700.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* inter-800 - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    src: url('../../fonts/inter-v13-latin-800.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../../fonts/inter-v13-latin-800.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* inter-900 - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    src: url('../../fonts/inter-v13-latin-900.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../../fonts/inter-v13-latin-900.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

body {
    font-family: 'Inter';
    font-size: 13px;
    scroll-behavior: smooth;
}

.login {
    margin: 0 auto;
    width: 80%;
    background-color: #fff;
   /* height: 638px !important;*/
    overflow: hidden;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.08);
}
.login .row{
    height: 100%;
}
.login-img{
    /* background-image: url(../../../public/images/login_img.webp);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
    background-color: var(--third);
}
.login-form{
    padding: 25px 50px;
}
.login-form .float-form input{
    padding: 8px 10px 10px 0;
    width: 100%;
    color: #000;
    font-size: inherit;
    background: #fff;
    border: none;
    border-bottom: 1px solid #e0e0e0;
}
.eye_pass{
    position: absolute;
    right: 0;
    top: 8px;
}
.login-form .float-form input:focus {
    outline: none;
}
.border-line{
    position: relative;
    display: block;
    width: 100%;
}
.border-line:after, .border-line:before {
    content: "";
    height: 1px;
    width: 50%;
    -webkit-transform: scale(0);
    transform: scale(0);
    bottom: 1px;
    position: absolute;
    will-change: transform;
    background: var(--primary);
    transition: transform .2s ease;
}
.border-line:before {
    left: 50%;
    -webkit-transform-origin: left;
    transform-origin: left;
}
.border-line:after {
    right: 50%;
    -webkit-transform-origin: right;
    transform-origin: right;
}
.login-form .float-form input:focus+.border-line:after, .login-form .float-form input:focus+.border-line:before{
    -webkit-transform: scale(1) !important;
    transform: scale(1) !important;
}
.login-form .float-form input:focus~.float_label{
    transform: translateY(-10px) scale(.8);
}
.float_label{
    color: #878787;
    font-size: 16px;
    font-weight: 400;
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    transition: -webkit-transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease, -webkit-transform .2s ease;
    will-change: transform;
    -webkit-transform-origin: left;
    transform-origin: left;
}

.fade-up{
    animation: myAnim 2s ease-in-out 0s 1 normal forwards;
}
.scale_animation{
    animation: scale 1s ease-in-out 0s 1 normal forwards;
}
.fade-left{
    animation: fade-left 2s ease-in-out 0s 1 normal forwards;
}
.fade-center{
    animation: fade-center 2s ease-in-out 0s 1 normal forwards;
}
.fade-center2{
    animation: fade-center2 2s ease-in-out 0s 1 normal forwards;
}
.mover {
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
}
@keyframes myAnim {
	0% {
		animation-timing-function: ease-in;
		opacity: 0;
		transform: translateY(250px);
	}

	38% {
		animation-timing-function: ease-out;
		opacity: 1;
		transform: translateY(0);
	}

	55% {
		animation-timing-function: ease-in;
		transform: translateY(65px);
	}

	72% {
		animation-timing-function: ease-out;
		transform: translateY(0);
	}

	81% {
		animation-timing-function: ease-in;
		transform: translateY(28px);
	}

	90% {
		animation-timing-function: ease-out;
		transform: translateY(0);
	}

	95% {
		animation-timing-function: ease-in;
		transform: translateY(8px);
	}

	100% {
		animation-timing-function: ease-out;
		transform: translateY(0);
	}
}
@keyframes scale {
	0% {
		transform: scale(0);
		transform-origin: 50% 0%;
	}

	100% {
		transform: scale(1);
		transform-origin: 50% 0%;
	}
}
@keyframes fade-left {
	0% {
		opacity: 0;
		transform: translateX(-50px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
@keyframes fade-center {
	0% {
		opacity: 0;
	}

	100% {
		opacity: .1;
	}
}
@keyframes fade-center2 {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}

.login .Mui-focused {
    color: var(--primary) !important;
}
.MuiInput-input{
    color: #000 !important;
}
.MuiInput-underline::after{
  border-bottom-color: var(--primary) !important;
  border-bottom-width:2px !important;
}
.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
  border-bottom-color: #555 !important;
  border-bottom-width:2px !important;
}
.Mui-focused fieldset{
    border-color: #6F7E8C !important;
    border-bottom-width:2px !important;
}
input{
    font-size:16px !important;
}
.login_svg .st0{fill:#49525E;}.login_svg .st1{fill:#E3B5AA;}.login_svg .st2{fill:#FFFFFF;}.login_svg .st3{fill:none;stroke:#C87E84;stroke-width:0.5;stroke-miterlimit:10;}.login_svg .st4{fill:#333841;}.login_svg .st5{fill:var(--primary);}.login_svg .st6{fill:#D78F97;}.login_svg .st7{fill:var(--primary-dark);}.login_svg .st8{opacity:0.4;}.login_svg .st9{fill:#C87E84;}.login_svg .st10{fill:#C33E5D;}.login_svg .st11{fill:#6CABBD;}.login_svg .st12{fill:#A3D5E6;}.login_svg .st13{fill:var(--secondary);}.login_svg .st14{clip-path:url(#SVGID_2_);fill:#D78F97;}.login_svg .st15{clip-path:url(#SVGID_2_);fill:#D68F97;}.login_svg .st16{clip-path:url(#SVGID_2_);fill:#D58F97;}.login_svg .st17{clip-path:url(#SVGID_2_);fill:#D39097;}.login_svg .st18{clip-path:url(#SVGID_2_);fill:#D29097;}.login_svg .st19{clip-path:url(#SVGID_2_);fill:#D19097;}.login_svg .st20{clip-path:url(#SVGID_4_);fill:#D68F97;}.login_svg .st21{clip-path:url(#SVGID_4_);fill:#D58F97;}.login_svg .st22{clip-path:url(#SVGID_4_);fill:#D39097;}.login_svg .st23{clip-path:url(#SVGID_4_);fill:#D29097;}.login_svg .st24{clip-path:url(#SVGID_6_);fill:#D78F97;}.login_svg .st25{clip-path:url(#SVGID_6_);fill:#D68F97;}.login_svg .st26{clip-path:url(#SVGID_6_);fill:#D58F97;}.login_svg .st27{clip-path:url(#SVGID_6_);fill:#D39097;}.login_svg .st28{clip-path:url(#SVGID_6_);fill:#D29097;}.login_svg .st29{clip-path:url(#SVGID_6_);fill:#D19097;}.login_svg .st30{clip-path:url(#SVGID_8_);fill:#D68F97;}.login_svg .st31{clip-path:url(#SVGID_8_);fill:#D58F97;}.login_svg .st32{clip-path:url(#SVGID_8_);fill:#D39097;}.login_svg .st33{clip-path:url(#SVGID_8_);fill:#D29097;}


.login_now{
    padding-bottom:50px;
}

{/* LoginPopup */}

.modal-popup {
    display: block;
    position: fixed;
    z-index: 1022;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgb(0,0,0);
    background-color: rgb(0 0 0 / 55%);
}

.modal-content {
    background-color: #fefefe;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    max-width: 400px;
    border-radius: 11px;
    box-shadow: 0px 0px 32px #00000063;
}

.close {
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    right : 30px;
    top : 10px;
}

    .close:hover,
    .close:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }

.login-form-popup {
    padding: 10px 20px;
}
.login_or_span {
    display: block;
    text-align: center;
    margin: 10px 0;
    background: linear-gradient(45deg, transparent, #fff, transparent);
    position: relative;
    font-size:12px;
}

@media screen and (max-width:768px){
    .login {
        height: 100% !important;
    }
}
.pop_eye {
    top: 50%;
    right: 0;
    transform: translate(-5px, -20%);
}
.modal-popup .form-control {
    background-color:#f2f2f2;
    /*box-shadow: 0px 1px 4px 0px inset #0e0e0e17;*/
}
.modal-popup .form-control:focus {
    box-shadow: 0 0 0 0.25rem rgb(0 150 136 / 14%) !important;
    border-color: #27225d !important;
}