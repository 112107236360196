/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.st0{fill:var(--secondary);}
.st1{fill-rule:evenodd;clip-rule:evenodd;fill:var(--secondary);}
.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#979797;}
.st3{fill-rule:evenodd;clip-rule:evenodd;fill:#E3B5AA;}
.st4{fill-rule:evenodd;clip-rule:evenodd;fill:#F6F6F7;}
.st5{fill-rule:evenodd;clip-rule:evenodd;fill:#F0CA30;}
.st6{fill-rule:evenodd;clip-rule:evenodd;fill:#CACACA;}
.st7{fill-rule:evenodd;clip-rule:evenodd;fill:#C87D50;}
.st8{fill-rule:evenodd;clip-rule:evenodd;fill:#333841;}
.st9{fill-rule:evenodd;clip-rule:evenodd;fill:#49525E;}
.st10{fill-rule:evenodd;clip-rule:evenodd;fill:#DA9064;}
.st11{fill-rule:evenodd;clip-rule:evenodd;fill:var(--primary);}
.st12{fill-rule:evenodd;clip-rule:evenodd;fill:var(--primary-dark);}
.st13{fill-rule:evenodd;clip-rule:evenodd;fill:#EAAA28;}
.st14{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
.st15{fill-rule:evenodd;clip-rule:evenodd;fill:#E4E4E4;}
.st16{fill-rule:evenodd;clip-rule:evenodd;fill:#7F7F7F;}
.st17{fill:var(--primary);}
.st18{fill:#FFFFFF;}
.st19{fill:#DB5F4B;}
.st20{fill:#FDFCFF;}
.st21{clip-path:url(#SVGID_2_);}
.st22{fill:#F0C85D;}
.st23{opacity:0.5;}
.st24{fill:#954EAC;}
.st25{fill:#C179AC;}
.st26{fill:#FAEDE6;}
.st27{opacity:0.4;fill:#F1C88D;}
.st28{fill:#FF9385;}
.st29{clip-path:url(#SVGID_4_);}

.mover {
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
}
@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}