/* inter-regular - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/inter-v13-latin-regular.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('./fonts/inter-v13-latin-regular.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* inter-500 - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url('./fonts/inter-v13-latin-500.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('./fonts/inter-v13-latin-500.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* inter-600 - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url('./fonts/inter-v13-latin-600.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('./fonts/inter-v13-latin-600.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* inter-700 - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/inter-v13-latin-700.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('./fonts/inter-v13-latin-700.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* inter-800 - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    src: url('./fonts/inter-v13-latin-800.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('./fonts/inter-v13-latin-800.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* inter-900 - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    src: url('./fonts/inter-v13-latin-900.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('./fonts/inter-v13-latin-900.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

body {
    font-family: 'Inter' !important;
    font-size: 13px;
    scroll-behavior: smooth;
}

:rootInitialTheme {
    --primary: #27225f;
    --primary-dark: #84ced1;
    --info-dark: #1b8fa6;
    --secondary: #84ced1;
    --third: #333841;
}

/* Orange */
:root {
    --primary: #ff7a4c;
    --primary-dark: #ff7a4c;
    --info-dark: #1b8fa6;
    --secondary: #ff7a4c;
    --third: #333841;
}

:rootGreen {
    --primary: #8aab71;
    --primary-dark: #8aab71;
    --info-dark: #1b8fa6;
    --secondary: #8aab71;
    --third: #333841;
}

:rootLightBlue {
    --primary: #8488b9;
    --primary-dark: #8488b9;
    --info-dark: #1b8fa6;
    --secondary: #8488b9;
    --third: #333841;
}


:rootGolden {
    --primary: #a57c49;
    --primary-dark: #a57c49;
    --info-dark: #1b8fa6;
    --secondary: #a57c49;
    --third: #333841;
}

:rootlightOrange {
    --primary: #ff8557;
    --primary-dark: #ff8557;
    --info-dark: #1b8fa6;
    --secondary: #ff8557;
    --third: #333841;
}

:rootLast {
    --primary: #64a591;
    --primary-dark: #64a591;
    --info-dark: #1b8fa6;
    --secondary: #64a591;
    --third: #333841;
}

.bg-primary {
    background-color: var(--primary) !important;
    color: #fff !important;
}

.bg-primary-dark {
    background-color: var(--primary-dark) !important;
    color: #fff !important;
}

.bg-secondary {
    background-color: var(--secondary) !important;
    color: #fff !important;
}

.bg-third {
    background-color: var(--third) !important;
    color: #fff !important;
}

.text-theme {
    color: var(--primary) !important;
}

.text-theme2 {
    color: var(--secondary);
}

a {
    text-decoration: none;
    color: inherit;
}

    a:hover {
        color: var(--primary-dark);
    }

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

code {
    color: #E01A76;
}

.br-20 {
    border-radius: 20px;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.disabledBTN {
    background-color: #d9d9d9 !important;
    color: #959595 !important;
    cursor: not-allowed !important;
}

    .disabledBTN:hover {
        background-color: #d9d9d9 !important;
        color: #959595 !important;
    }

/* -------------------------------------header---------------------------------------- */
.navbar {
    background-color: #fff;
}

    .navbar .srch_btn {
        border-radius: 0 30px 30px 0;
        width: 10%;
        margin: 0px 2px;
        height: 30.5px;
    }


.srch_box_input {
    width: 100%;
}

.srch_box {
    width: 100%;
    z-index: 100;
    top: 48px;
    background-color: #fff;
    border-radius: 10px;
    transition: all .3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 15px 64px 0px;
}

.srch_box_item {
    padding: 7px 10px;
    transition: all .2s ease-in;
}

    .srch_box_item:hover {
        background-color: #e4e4e4;
    }

    .srch_box_item.active {
        background-color: #e4e4e4;
    }

.navbar .input-group {
    z-index: 100;
    /*border: 2px solid #85ced0;*/
    border: 2px solid var(--secondary);
    border-radius: 30px;
}

.closesrch {
    position: absolute !important;
    right: 0;
    top: 2px;
    padding: 8px !important;
}

.srch_value {
    font-weight: 600;
    color: var(--primary);
}

.srch_type {
    font-size: 12px;
    color: #555;
}

.closesrch:hover {
    background-color: #f4f4f4;
}

.navbar .over-lay {
    position: fixed;
    z-index: 99;
    background-color: #00000070;
    top: 0;
    right: 0;
    transition: all .3s ease-in-out;
    width: 100%;
    height: 100%;
}

.navbar-brand img {
    width: 178px;
}

.bottom_bar .divider {
    border-bottom: 1px solid #232323;
    border-image: linear-gradient(to right, #d9d8d8, white) 1;
    margin: 5px 50px 5px 0px;
}

.bottom_bar {
    line-height: normal;
    background-color: #fff;
    padding: 0px 0px 10px 0px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

    .bottom_bar ul a.active::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        background-color: var(--secondary);
        bottom: -3px;
        left: 0;
        border-radius: 10px;
    }

    .bottom_bar ul a::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        transform: scale(0);
        background-color: #e9e9e9;
        top: 0;
        left: 0;
        z-index: -1;
        border-radius: 10px;
        transition: transform .2s cubic-bezier(.345,.115,.135,1.42),background .15s ease-out,box-shadow .2s ease-out;
    }

    .bottom_bar ul a:hover:after {
        transform: scale(1)
    }

    .bottom_bar ul a {
        font-size: 15px;
        font-weight: 500;
        position: relative;
        padding: 10px 20px;
        color: #000;
        z-index: 2;
        border-radius: 10px;
        cursor: pointer;
    }

#google_translate_element {
    position: relative;
    background-color: transparent !important;
    z-index: 2;
    margin: 0 5px;
}

    #google_translate_element::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        transform: scale(0);
        background-color: #e9e9e9;
        top: 0;
        left: 0;
        z-index: -1;
        border-radius: 10px;
        transition: transform .2s cubic-bezier(.345,.115,.135,1.42),background .15s ease-out,box-shadow .2s ease-out;
    }

    #google_translate_element:hover:after {
        transform: scale(1);
    }

.navbar .form-control:focus {
    box-shadow: unset;
    border: 0;
}

.headsignup_btn .dropdown_item {
    background-color: #fff;
    min-width: 280px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    top: 30px;
    right: 0;
    z-index: 2;
    visibility: hidden;
}

    .headsignup_btn .dropdown_item ul li a {
        padding: 8px 10px;
        display: inline-block;
        width: 100%;
        font-size: 14px;
    }

        .headsignup_btn .dropdown_item ul li a:hover {
            background-color: #f1f1f1;
        }

/* .headsignup_btn::after{
  content: '';
  position: absolute;
  visibility: hidden;
  top: 30px;
  left: 35%;
  background-color: #ffffff;
  width: 20px;
  height: 20px;
  z-index: 0;
  transform: rotate(45deg);
  box-shadow: 0 1px 5px 2px #0000001a;
} */
.headsignup_btn {
    padding: 5px 12px 6px 12px;
    background-color: var(--secondary);
}

    /*.headsignup_btn:hover {
}*/

    .headsignup_btn:hover .dropdown_item {
        visibility: visible;
    }

    .headsignup_btn:hover::after {
        visibility: visible;
    }

.main_dropdown_list {
    position: relative;
}

.catog_link {
    padding: 10px 15px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 500;
}

    .catog_link:hover {
        background-color: #f4f4f4;
    }

.main_dropdown_item {
    position: absolute;
    width: 800px;
    z-index: 999;
    top: 35px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    padding: 15px 25px;
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease;
}

@keyframes fadeup {
    0% {
        transform: translateY(50px)
    }

    100% {
        transform: translateY(35px)
    }
}

.main_dropdown_list:hover .main_dropdown_item {
    visibility: visible;
    opacity: 1;
    animation: fadeup .2s ease-in;
}

.main_dropdown_item .mdi_heading {
    text-transform: capitalize;
    color: var(--primary);
    padding: 12px 0 0px;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 25px;
    font-weight: 700;
    font-size: 16px;
}

    .main_dropdown_item .mdi_heading:nth-of-type(1) {
        padding: 0;
    }

    .main_dropdown_item .mdi_heading:hover {
        overflow: initial;
    }




.main_dropdown_item .main_dropdown_column {
    float: left;
    display: block;
    position: relative;
    /*height: 436px;*/
    /*width:200px;*/
    padding-top: 4px;
}

.main_dropdown_item .mdi_link {
    color: #282c3f;
    line-height: 23px !important;
    font-weight: 400;
    display: block;
    font-size: 14px;
    transition: all .3s ease;
    padding: 0;
}


.main_dropdown_column ul {
    display: block;
    position: relative;
    float: left;
    width: 216px;
    margin: 0;
    padding: 0;
}

/* -----------------------------------------------header end-------------------------------------- */
section {
    padding: 50px 0 0;
}

.heading {
    position: relative;
}

    .heading h4 {
        margin: 0;
    }

.mybtn:hover {
    background-color: var(--primary-dark) !important;
}

.mybtn {
    white-space: nowrap;
}

.mybtnOtpLogin:hover {
    background-color: var(--info-dark) !important;
}

.mybtnOtpLogin {
    white-space: nowrap;
}

.products_list_scroll {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
    border: 1px solid #cccccc;
}

    .products_list_scroll::-webkit-scrollbar {
        display: none;
    }

    .products_list_scroll .scroll_inner {
        display: flex;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

.product_item {
    width: 100%;
    height: 100%;
    padding: 15px 15px 0 15px;
    flex-shrink: 0;
    background-color: #fff;
    margin: 10px 0;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 15px 64px 0px;
    border-radius: 5px;
    z-index: 99;
    overflow: hidden;
}

    .product_item:nth-child(5) {
        border-right: 0;
    }

    .product_item .figure-caption {
        text-transform: uppercase;
    }

    .product_item .price--highlight {
        margin-right: 10px;
        color: #e00;
        font-size: 18px;
    }

    .product_item .price--compare {
        font-size: 14px;
        color: #808080;
        text-decoration: line-through;
    }

    .product_item .prod_discount {
        color: var(--primary);
    }

    .product_item .figure {
        width: 100%;
        height: 212px;
        overflow: hidden;
        margin: 0 auto;
    }

        .product_item .figure img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

.productName {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.vabtn {
    position: relative;
    transition: all .3s ease-in-out;
}

    .vabtn svg {
        position: absolute;
        right: -15px;
        top: 0;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease-in-out;
    }

    .vabtn:hover {
        padding-right: 25px;
    }

        .vabtn:hover svg {
            visibility: visible;
            right: 0px;
            opacity: 1;
        }

.featured_brands .figure img {
    transition: all .3s ease-in-out;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.brand_img {
    height: 130px;
    width: 100%;
    padding: 15px;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

    .brand_img img {
        height: 100%;
        width: 100%;
    }

.featured_brands .figure {
    width: 100%;
    height: 180px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.featured_brands .slick-slider {
    border: inherit;
}

.featured_brands .figure:hover img {
    transform: scale(1.01);
}

.product_top_button {
    position: absolute;
    top: 15px;
    right: 0px;
    opacity: 0;
    visibility: hidden;
    transition: .3s ease;
}

.product_item:hover .product_top_button {
    visibility: visible;
    opacity: 1;
    right: 20px
}

.essential_products .product_item {
    width: 100%;
}

/*.slick-slider {
    border: 1px solid #cccccc;
}*/

.add_card .banner {
    padding: 0 40px;
    background-color: var(--third);
    overflow: hidden;
}

    .add_card .banner .img img {
        width: 100%;
        height: 100%;
        -o-object-fit: contain;
        object-fit: contain;
        -o-object-position: bottom;
        object-position: bottom;
        position: relative;
        z-index: 10;
    }

    .add_card .banner .img {
        padding-top: 15px;
        position: relative;
        height: 180px;
    }

        .add_card .banner .img::before {
            position: absolute;
            content: "";
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            border-radius: 50%;
            width: 400px;
            height: 400px;
            background-image: radial-gradient(rgba(110, 255, 246, 0.3333333333), transparent, transparent);
        }

.swiper-pagination-bullet-active {
    background-color: var(--primary);
    width: 24px;
    border-radius: 30px;
}

.cta .form-group {
    position: relative;
    height: 45px;
    background-color: #fff;
    border-radius: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 5px 5px 5px 20px;
}

    .cta .form-group .icon {
        /*color: #000;*/
        color: var(--secondary);
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }

    .cta .form-group input {
        border: 0;
        background-color: transparent;
        -webkit-padding-start: 10px;
        padding-inline-start: 10px;
        width: 100%;
    }

        .cta .form-group input:focus-visible {
            outline: none;
        }

    .cta .form-group button {
        font-size: 13px;
        border: 0;
        height: 35px;
        /*background-color: #000;*/
        background-color: var(--secondary);
        color: #fff;
        border-radius: 30px;
        text-transform: uppercase;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        padding: 5px 20px;
    }

.categories_img {
    height: 130px;
    width: 130px;
    overflow: hidden;
    border-radius: 100%;
    transition: .1s ease-in;
}

.categories_list .categories_img img {
    width: 100%;
}

.categories_list {
    width: 100%;
    /*background-color: #fff;*/
    padding: 15px;
    margin: 0 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transition: .3s ease-in-out;
}

    .categories_list:hover .categories_img {
        transform: scale(1.02);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    }


    .categories_list p {
        font-weight: 500;
        font-size: 15px;
        text-align: center;
    }

.cat_list_img {
    width: 125px;
    height: 125px;
    overflow: hidden;
    object-fit: cover;
    border-radius: 100%;
    position: relative;
}

    .cat_list_img figure {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 125px;
        height: 125px;
        border-radius: 100%;
        border: 5px solid #fff;
        overflow: hidden;
    }

    .cat_list_img img {
        width: 100%;
        transition: all .5s ease;
    }

    .cat_list_img::before {
        content: '';
        position: absolute;
        border-radius: 100%;
        width: 125px;
        height: 125px;
        border: 2px solid var(--primary);
        top: 50%;
        left: 50%;
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.9);
        transition: all .5s ease;
    }

.cat_list a:hover .cat_list_img img {
    transform: scale(0.9);
}

.cat_list a:hover .cat_list_img::before {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
}

.cat_list {
    overflow-x: scroll;
    width: 100%;
}

    .cat_list::-webkit-scrollbar {
        display: none;
    }

    .cat_list li {
        margin-right: 20px;
        padding: 0 5px;
        flex: 0 15%;
    }

        .cat_list li p {
            font-weight: 600;
        }

    .cat_list a:hover p {
        color: var(--primary);
    }

.slick-prev:before,
.slick-next:before {
    font-family: 'FontAwesome';
    font-size: 20px;
    line-height: 1;
    opacity: 0.5;
    color: var(--primary);
}

.slick-prev,
.slick-next {
    background-color: #edeff6;
    width: 40px;
    height: 80px;
    border-radius: 6px;
}

    .slick-prev:hover::before,
    .slick-next:hover::before {
        color: #fff;
        opacity: 0.8;
    }

    .slick-prev:hover,
    .slick-next:hover {
        color: transparent;
        outline: none;
        background-color: var(--primary);
    }

    .slick-prev:focus,
    .slick-next:focus {
        color: transparent;
        outline: none;
        background: var(--primary-dark);
    }

.slick-prev {
    left: -50px;
}

.slick-next {
    right: -50px;
}

    .slick-next:before {
        content: '\f105';
    }

.slick-prev:before {
    content: '\f104';
}

/* ---------------------sideDrawer-------------------------------- */
.side_dropdown {
    width: 260px;
}

    .side_dropdown li a {
        padding: 8px 10px;
        text-decoration: none;
        color: var(--secondary);
        display: flex;
    }

.sidebar_mobe .MuiDrawer-paper {
    width: 260px;
}

.sidebar_mobe .accordion-button:not(.collapsed) {
    color: #fff;
    background-color: var(--primary);
    box-shadow: unset;
    border-radius: 0;
}

.sidebar_mobe .accordion-button {
    padding: 10px;
    border: 0;
    box-shadow: unset;
    border-radius: 0;
    font-weight: 700;
    color: var(--third);
}

.sidebar_mobe .accordion-item {
    border: 0;
}

.sidebar_mobe .side_link {
    color: var(--third);
    display: block;
    padding: 4px 0;
}

.sidebar_mobe .single_link {
    padding: 4px 0 4px 10px;
    font-weight: 700;
    color: var(--third);
}

.collapsing {
    transition: all .3s ease-in-out;
}

.accordion-button:not(.collapsed)::after {
    background-image: url(../public/images/icons/minus.svg);
    transform: unset;
}

.accordion-button::after {
    background-image: url(../public/images/icons/plus.svg);
    background-size: 12px;
}

.form-check-input:checked {
    background-color: var(--primary);
    border-color: var(--primary);
}

.form-check-input:focus {
    border-color: var(--primary);
    outline: 0;
    box-shadow: inherit;
}

.form-check-input:checked[type=checkbox] {
    background-color: var(--primary) !important;
}

/* -------------------------------quickview-------------------------------- */
.quickview {
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

    .quickview figure {
        height: 600px;
        overflow: hidden;
    }

        .quickview figure img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

.quickview_content {
    padding: 20px 10px;
}

.view_price {
    margin-right: 10px;
    color: #e00;
    font-size: 28px;
}

.view_regularprice {
    font-size: 18px;
    text-decoration: line-through;
}

.product_add_count {
    border-bottom: 1px solid #cccccc;
    padding: 10px 0;
    margin: 10px 0 20px;
}

    .product_add_count .Mui-disabled {
        pointer-events: visible !important;
        cursor: not-allowed !important;
    }

.add_count {
    background-color: #fff;
    border-radius: 5px;
    display: -webkit-box;
    width: 100px;
    display: -ms-flexbox;
    position: relative;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border: 1px solid rgba(153, 153, 153, 0.2);
}

    .add_count span {
        padding: 0 5px;
    }

.cart_product .discount {
    font-size: 14px;
    font-weight: 400;
    color: #00AE4D;
    white-space: nowrap;
}

.cart_product .cart_price {
    padding: 1px 8px;
    /* border: 1px dashed; */
    white-space: nowrap;
    background-color: var(--primary);
    color: #fff;
    border-radius: 5px;
    margin-top: 48px;
}

[class~='checkout_card_product']:last-of-type {
    border-bottom: 0 !important;
}


/* ---------------------------------------filter & product page------------------------------- */
.filter_head {
    font-size: 16px;
    font-weight: 500;
    background-color: #fff;
    margin-bottom: 10px;
    display: block;
    padding: 10px;
    border-radius: 5px;
}

.filter {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 15px 64px 0px;
}

.filter_price input {
    width: 100%;
    border-bottom: 1px solid #ccc;
    border: 0;
    background-color: transparent;
}

    .filter_price input:focus {
        outline: inherit;
    }

.MuiSlider-thumb {
    color: var(--primary);
}

.MuiSlider-rail {
    color: #f9b870;
}

.MuiSlider-track {
    color: var(--secondary);
}

.filter-top-left .sortby {
    cursor: pointer;
    margin-right: 20px;
    position: relative;
}

    .filter-top-left .sortby .sortby_drpdwn button {
        border: 0;
        background: transparent;
        display: block;
        padding: 5px 10px;
        width: 100%;
        text-align: left;
        font-size: 14px;
    }

        .filter-top-left .sortby .sortby_drpdwn button:hover {
            background: #f1f1f1;
        }

    .filter-top-left .sortby .sortby_drpdwn {
        position: absolute;
        top: 34px;
        left: 0;
        padding: 5px 0;
        background-color: #fff;
        box-shadow: 0px 0px 8px 0 rgb(0 0 0 / 15%);
        width: 200px;
        transition: all .3s ease;
        z-index: 999;
    }

.filter-top-left span {
    font-size: 14px;
}

.show {
    visibility: visible;
    opacity: 1;
}

.hide {
    visibility: hidden;
    opacity: 0;
}

.product_list {
    position: relative;
    flex-wrap: wrap;
    width: 100%;
    z-index: 1;
}

.newlaunch {
    padding: 8px 34px 8px;
    background-color: var(--secondary);
    left: -36px;
    top: 13px;
    line-height: normal;
    text-align: center;
    font-size: 10px;
    color: #000;
    border-radius: 3px;
    font-weight: 700;
    transform: rotate(314deg);
    box-shadow: rgb(0 0 0 / 8%) -2px 4px 3px 0px;
}

.featured_products .product_list .product_item {
    width: 20%;
    border: 0;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 15px 64px 0px;
    margin: 0 10px 0 0;
}

.slick-slider .product_item {
    padding-bottom: 15px;
}

.product_list .product_item {
    border: 0;
    background-color: #fff;
}

.product_list .product_item_horizontal,
.cart_product {
    width: 100%;
    display: flex;
    align-items: flex-start;
}

    .product_list .product_item_horizontal .figure,
    .cart_product figure {
        width: 160px;
        height: 160px;
        overflow: hidden;
        margin: 0 auto;
    }

        .cart_product figure img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

    .cart_product .checkout_btn .btn {
        width: 45px;
        height: 100%;
    }

    .product_list .product_item_horizontal .product_top_button {
        display: none;
    }

    .product_list .product_item_horizontal .product_info,
    .cart_product .product_info {
        display: flex;
        justify-content: space-between;
        flex: 1 0 0;
        margin: 15px;
        align-items: flex-start;
    }

        .product_list .product_item_horizontal .product_info .buttons-group {
            display: flex;
            flex-direction: column;
        }

            .product_list .product_item_horizontal .product_info .buttons-group button {
                padding: 5px 35px;
                width: auto !important;
                margin: 5px 0;
                white-space: nowrap;
            }

.slick-track {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.breadcrumb-item {
    padding: 0px 10px;
    border-radius: 30px;
    color: var(--third);
}

.breadcrumb span {
    margin: 0 5px;
}

@media screen and (max-width:768px) {
    .product_list .product_item {
        width: 100% !important;
    }

    .product_list .product_item_horizontal {
        width: 100%;
    }

    .product_item .price--highlight {
        font-size: 16px;
    }

    .product_item .price--compare {
        font-size: 12px;
    }

    .product_item .prod_discount {
        font-size: 14px;
    }

    .product_list .product_item_horizontal .product_info .buttons-group {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

        .product_list .product_item_horizontal .product_info .buttons-group button {
            padding: 5px 10px;
            margin: 5px 5px;
            font-size: 12px;
            white-space: nowrap;
        }

    .product_list .product_item_horizontal .product_info {
        display: block;
    }

    .product_list .col-6 {
        padding: 0px !important;
    }

    .goog-te-gadget-simple {
        font-size: 8pt !important;
    }
}



/* ------------------------------myprofile------------------------------------------ */
.myprofile .profile_head .media_img {
    width: 50px;
    height: 50px;
    padding: 10px;
    background-color: #a6a6a6;
    border-radius: 100%;
}

    .myprofile .profile_head .media_img span {
        font-size: 24px;
        color: #f1f1f1;
        font-weight: 600;
    }

.myprofile .profile_head .head_mail {
    font-size: 12px;
}

.myprofile .profile_head .head_info span {
    font-size: 20px;
}

.myprofile .profile_links button {
    width: 100%;
    border: 0;
    border-radius: 5px;
    background-color: #fff;
    text-align: left;
    margin-bottom: 8px;
    padding: 10px 15px;
}

.myprofile .tab_body input,
.select_pay_mode input,
.adres_form_body input,
.adres_form_body select,
.contact_form input,
.contact_form textarea,
.reviewtxt textarea {
    /*background-color: #ebf0f7;*/
    border: 1px solid #ebf0f7;
}

    .myprofile .tab_body input:focus,
    .select_pay_mode input:focus,
    .adres_form_body input:focus,
    .adres_form_body select:focus,
    .contact_form input:focus,
    .contact_form textarea:focus,
    .reviewtxt textarea:focus {
        box-shadow: inherit;
        outline: inherit;
    }

.address_tab label input[type='radio'] {
    display: none;
}

.address_tab label input:checked + .adrss_body {
    background-color: #eefffe;
    border: 1px solid var(--primary);
}

.address_tab .adrss_body {
    padding: 20px 20px 20px 45px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px dashed transparent;
}

.circle_radio::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    top: 40%;
    opacity: 0.4;
    border-radius: 100%;
    -webkit-box-shadow: 0px 0px 0px 2px #969696;
    box-shadow: 0px 0px 0px 2px #969696;
    border-radius: 100%;
    left: 10px;
    transform: translate(0px, -50%);
}

.address_tab label input:checked + .adrss_body .circle_radio::before {
    background-color: transparent;
    border-color: unset;
    background-image: url(../public/images/checkmark.svg);
    -webkit-box-shadow: 0px 0px 0px 2px #47cc70;
    box-shadow: 0px 0px 0px 2px #47cc70;
    opacity: 1;
}

.address_tab .customerName {
    font-size: 20px !important;
    font-weight: 600;
}

.typeofAdres {
    background-color: #f8f8f8;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    border: 1px dashed #c3c3c3;
}

.eye_pwrd {
    position: absolute;
    right: 5px;
    top: 1px;
}

/* ------------------------------------Tracking--------------------------------------------- */
.md-stepper-horizontal {
    display: table;
    width: 100%;
    margin: 0 auto;
    background-color: #FFFFFF;
    box-shadow: 0 3px 8px -6px rgba(0, 0, 0, .50);
}

    .md-stepper-horizontal .md-step {
        display: table-cell;
        position: relative;
        padding: 24px;
    }

        .md-stepper-horizontal .md-step:active {
            border-radius: 15% / 75%;
        }

        .md-stepper-horizontal .md-step:first-child:active {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .md-stepper-horizontal .md-step:last-child:active {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .md-stepper-horizontal .md-step:hover .md-step-circle {
            background-color: #757575;
        }

        .md-stepper-horizontal .md-step:first-child .md-step-bar-left,
        .md-stepper-horizontal .md-step:last-child .md-step-bar-right {
            display: none;
        }

        .md-stepper-horizontal .md-step .md-step-circle {
            width: 30px;
            height: 30px;
            margin: 0 auto;
            background-color: #999999;
            border-radius: 50%;
            text-align: center;
            line-height: 30px;
            font-size: 16px;
            font-weight: 600;
            color: #FFFFFF;
        }

    .md-stepper-horizontal.green .md-step.active .md-step-circle {
        background-color: #00AE4D;
    }

    .md-stepper-horizontal.orange .md-step.active .md-step-circle {
        background-color: #F96302;
    }

    .md-stepper-horizontal .md-step.active .md-step-circle {
        background-color: rgb(33, 150, 243);
    }

    .md-stepper-horizontal .md-step.done .md-step-circle:before {
        font-family: 'FontAwesome';
        font-weight: 100;
        content: "\f00c";
    }

    .md-stepper-horizontal .md-step.done .md-step-circle *,
    .md-stepper-horizontal .md-step.editable .md-step-circle * {
        display: none;
    }

    .md-stepper-horizontal .md-step.editable .md-step-circle {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }

        .md-stepper-horizontal .md-step.editable .md-step-circle:before {
            font-family: 'FontAwesome';
            font-weight: 100;
            content: "\f040";
        }

    .md-stepper-horizontal .md-step .md-step-title {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 600;
    }

    .md-stepper-horizontal .md-step .md-step-title,
    .md-stepper-horizontal .md-step .md-step-optional {
        text-align: center;
        color: rgba(0, 0, 0, .26);
    }

    .md-stepper-horizontal .md-step.active .md-step-title {
        font-weight: 600;
        color: rgba(0, 0, 0, .87);
    }

    .md-stepper-horizontal .md-step.active.done .md-step-title,
    .md-stepper-horizontal .md-step.active.editable .md-step-title {
        font-weight: 600;
    }

    .md-stepper-horizontal .md-step .md-step-optional {
        font-size: 12px;
    }

    .md-stepper-horizontal .md-step.active .md-step-optional {
        color: rgba(0, 0, 0, .54);
    }

    .md-stepper-horizontal .md-step .md-step-bar-left,
    .md-stepper-horizontal .md-step .md-step-bar-right {
        position: absolute;
        top: 36px;
        height: 1px;
        border-top: 1px solid #DDDDDD;
    }

    .md-stepper-horizontal .md-step .md-step-bar-right {
        right: 0;
        left: 50%;
        margin-left: 20px;
    }

    .md-stepper-horizontal .md-step .md-step-bar-left {
        left: 0;
        right: 50%;
        margin-right: 20px;
    }

/* -------------------------------Products details------------------------------------ */
.product_gallary .thumb_image img {
    width: 100%;
    height: 100px;
    border: 1px solid #ebebeb;
    padding: 5px;
}

.thumb_image .slick-prev,
.thumb_image .slick-next {
    background-color: #edeff6;
    position: relative;
    width: 100%;
    height: 35px;
    border-radius: 3px;
    right: 0;
    left: 0;
    transform: inherit;
}

    .thumb_image .slick-prev:before {
        transform: rotate(90deg);
        display: block;
    }

    .thumb_image .slick-next:before {
        transform: rotate(90deg);
        display: block;
    }

.thumb_image::-webkit-scrollbar {
    display: none;
}

.large_image {
    border: 1px solid #ebebeb;
    padding: 5px;
}

.product_gallary .large_image {
    width: 100%;
    height: 500px;
}

.large_image .l_img_container {
    width: 100% !important;
    height: 100% !important;
}

.l_img_simg {
    height: 100% !important;
    object-fit: contain;
}

.pd_info .head {
    font-size: 30px;
}

::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #d8a76a;
}


.pd_info .price--highlight {
    margin-right: 10px;
    color: var(--third);
    font-size: 30px;
    font-weight: 600;
}

.pd_info .price--compare {
    font-size: 16px;
    color: #a6a6a6;
    text-decoration: line-through;
}

.pd_info .prod_discount {
    color: var(--primary);
}

.buybtn,
.addbtn {
    padding: 15px;
    width: 200px;
}

.product_details .sticky-lg-top {
    top: 20%;
    transform: translateY(0px);
    z-index: 1010;
}

.cust_review table tr {
    vertical-align: middle;
}

    .cust_review table tr .progress-bar {
        background-color: var(--primary);
        height: 10px;
    }

    .cust_review table tr .progress {
        height: 10px;
        width: 185px;
    }

.customer_photos figure {
    width: 100px;
    height: 100px;
    aspect-ratio: 1/1;
}

    .customer_photos figure img {
        width: 100%;
    }

.customer_info span {
    font-size: 12px;
    background-color: var(--secondary);
    padding: 2px 5px;
    border-radius: 1px;
    display: inline-flex;
    align-items: baseline;
    color: #000;
}

.adrs_type {
    padding: 0px 8px 3px;
    border-radius: 3px;
    background-color: var(--secondary);
    width: fit-content;
}

.address_tab .tab_body p {
    font-size: 14px;
}

.adrs_type span {
    font-size: 10px;
    font-weight: 700;
}

.order_tab .ordr_info figure {
    width: 100px;
    height: 100px;
    overflow: hidden;
}

    .order_tab .ordr_info figure img {
        aspect-ratio: 1/1;
        width: 100%;
    }

.wishlist .productName {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 40px;
    text-overflow: ellipsis;
}

.select_colors .verrientItem {
    border: 1px solid #e2e2e2;
    color: #666;
    width: 80px;
    height: 80px;
    padding: 5px 5px;
}

    .select_colors .verrientItem img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .select_colors .verrientItem.active {
        border: 1px dashed var(--secondary);
        color: var(--secondary);
        font-weight: 600;
    }

/* -----------------------------------shopping card-------------------------------------- */
.cart_card {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 15px 64px 0px;
}

.cart_price span {
    font-size: 18px;
    font-weight: 600;
}

.summary table tr td:nth-child(2) {
    text-align: right;
}

/* -----------------------------checkout------------------------------------------- */
.select_pay_mode .label_box {
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    width: 100%;
}

.select_pay_mode label input[type='radio'] {
    display: none;
}

.select_pay_mode label input:checked + .adrss_body {
    background-color: #eefffe;
    border: 1px solid var(--primary);
}

.adrss_body {
    cursor: pointer;
}

.goog-te-gadget-simple {
    border: 0 !important;
    border-radius: 5px !important;
    padding: 5px !important;
    background-color: transparent !important;
}

.select_pay_mode .adrss_body {
    padding: 15px 20px 15px 45px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #f2f2f2;
    border: 1px dashed transparent;
}

.select_pay_mode .circle_radio::before {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    top: 50%;
    border-radius: 100%;
    border: 3px solid #fff;
    -webkit-box-shadow: 0px 0px 0px 2px #c5c5c5;
    box-shadow: 0px 0px 0px 2px #c5c5c5;
    border-radius: 100%;
    left: 10px;
    transform: translate(0px, -50%);
}

.select_pay_mode label input:checked + .adrss_body .circle_radio::before {
    background-color: #fff;
    border-color: #0066ff;
    box-shadow: 0px 0px 0px 2px #0066ff;
}

.select_pay_mode input:focus,
.adres_form_body input:focus,
.contact_form input:focus,
.contact_form textarea:focus,
.reviewtxt textarea:focus {
    background-color: #ebf0f7;
}

.close_btn {
    position: absolute;
    right: 10px;
    top: 8px;
}

.navbar .MuiOutlinedInput-root {
    padding: 0px !important;
    border-radius: 30px 0 0 30px !important;
}

.MuiOutlinedInput-root {
    padding: 0 !important;
}

.MuiOutlinedInput-input {
    padding: 7.5px 4px 7.5px 20px !important;
}

.Mui-focused {
    border-width: 1px !important;
    box-shadow: inherit !important;
}

/* -----------------------------------contact us----------------------------------- */
.contact_head {
    padding: 80px 30px;
    margin: 20px 0;
    background-image: url(../public/images/contact.jpg);
    background-position: center;
    background-size: cover;
    border-radius: 20px;
}

    .contact_head h1 {
        font-weight: 600;
        color: #fff;
    }


/* ---------------------------------------blog-------------------------------------------- */
.blogs_grid img {
    border-radius: 5px 5px 0 0;
}

.react-stars span {
    padding: 0 10px;
}

    .react-stars span:nth-of-type(1) {
        margin-top: 60px;
        padding: 0px;
    }

    .react-stars span:nth-of-type(2) {
        margin-top: 15px;
    }

    .react-stars span:nth-of-type(3) {
        margin-top: 0;
    }

    .react-stars span:nth-of-type(4) {
        margin-top: 15px;
    }

    .react-stars span:nth-of-type(5) {
        margin-top: 60px;
        padding: 0px;
    }

.rating .rateValue {
    position: absolute;
    left: 50%;
    bottom: 10%;
    transform: translate(-50%, 10px);
    font-size: 16px;
    text-align: center;
    font-weight: 500;
}

    .rating .rateValue .ratingtxt {
        font-size: 18px;
        color: var(--secondary);
    }

/* --------------------------footer-------------------------------------------- */
.foot-content {
    padding: 60px 0;
}

    .foot-content .links li a {
        line-height: 2;
        color: #666;
        text-decoration: none;
        font-size: 13px;
        transition: all .3s ease-in-out
    }

        .foot-content .links li a:hover {
            padding-left: 0px;
            color: var(--primary);
        }

    .foot-content .links {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

.fsz-18 {
    font-size: 18px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.foot_bottom {
    padding: 20px 0;
    background-color: #ebeef6;
}

    .foot_bottom .pay img {
        width: 240px;
    }

.links-item .li-cat li {
    flex: 0 50%;
}

.becomeseller {
    width: fit-content;
    display: block;
    padding: 5px 20px;
    background-color: var(--secondary);
    color: var(--third) !important;
}

.about-us img {
    width: 350px;
    float: left;
    margin: 0 10px 10px 0;
}

.about-us p {
    text-align: justify;
}

.online_dot {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: green;
    margin-right: 5px;
}

.modify_AutoComplate {
    cursor: pointer;
    padding: 8px;
}

    .modify_AutoComplate:hover {
        background: #eceff7;
    }


/* ---------------------------------------check mark animate ----------------------------------- */
#check-group {
    animation: 0.32s ease-in-out 1.03s check-group;
    transform-origin: center;
}

    #check-group #check {
        animation: 0.34s cubic-bezier(0.65, 0, 1, 1) 0.8s forwards check;
        stroke-dasharray: 0, 75px;
        stroke-linecap: round;
        stroke-linejoin: round;
    }

    #check-group #outline {
        animation: 0.38s ease-in outline;
        transform: rotate(0deg);
        transform-origin: center;
    }

    #check-group #white-circle {
        animation: 0.35s ease-in 0.35s forwards circle;
        transform: none;
        transform-origin: center;
    }

@keyframes outline {
    from {
        stroke-dasharray: 0, 345.576px;
    }

    to {
        stroke-dasharray: 345.576px, 345.576px;
    }
}

@keyframes circle {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(0);
    }
}

@keyframes check {
    from {
        stroke-dasharray: 0, 75px;
    }

    to {
        stroke-dasharray: 75px, 75px;
    }
}

@keyframes check-group {
    from {
        transform: scale(1);
    }

    50% {
        transform: scale(1.09);
    }

    to {
        transform: scale(1);
    }
}


.discount_card {
    border-radius: 20px 0 0 20px;
    background-color: var(--primary);
    box-shadow: rgb(38 57 77 / 21%) 16px 20px 30px -10px;
    position: relative;
    margin: 0px 30px 30px 0px;
    background-image: url(../public/images/wave.png)
}

    .discount_card:before {
        background: rgba(0, 0, 0, 0) url(../public/images/tri-promo-bottom-white.png) repeat scroll 0 0;
        content: "";
        height: 100%;
        right: -22px;
        position: absolute;
        top: 1px;
        transform: rotateX(351deg);
        width: 14px;
        z-index: 997;
    }

    .discount_card span:nth-of-type(1) {
        font-weight: 900;
        font-size: 18px;
        color: #fff;
    }

    .discount_card span:nth-of-type(2) {
        font-weight: 900;
        font-size: 50px;
        display: block;
        line-height: 50px;
        color: #fff;
    }

    .discount_card span:nth-of-type(3) {
        font-weight: 700;
        font-size: 18px;
        margin-left: 60%;
        background: #fff;
        color: var(--primary);
        padding: 1px 12px;
        border-radius: 5px;
    }

    .discount_card h4 {
        color: #e00;
        font-weight: 700;
        font-size: 18px;
        margin: 0;
    }

@media screen and (min-width:992px) {
    .container-fluid {
        width: 90%;
    }
}

@media screen and (max-width:768px) {
    .navbar-brand img {
        width: 100px;
    }

    .cat_list li {
        margin-right: 15px;
    }

    section {
        padding: 30px 0 0;
    }

    .product_item .figure {
        height: 190px;
    }

    .add_card .banner {
        padding: 30px 40px 0;
    }

    .foot-icons .col-6:nth-child(2) {
        border: 0 !important;
    }

    .cat_list_img::before {
        width: 100px;
        height: 100px;
    }

    .headsignup_btn .dropdown_item {
        left: unset;
        right: 0;
        z-index: 999;
    }

    .srch_box_input {
        width: 100%;
    }

    .quickview figure {
        height: 300px;
        overflow: hidden;
    }

    .customer_photos {
        width: 100%;
        overflow-x: scroll;
    }

        .customer_photos::-webkit-scrollbar {
            display: none;
        }

    .thumb_image {
        width: 100%;
    }

        .thumb_image::-webkit-scrollbar {
            display: none;
        }

    .buybtn,
    .addbtn {
        padding: 15px;
        width: 100%;
    }

    .product_add_count .MuiButtonBase-root {
        width: 100%;
    }

    .pd_info .productName {
        font-size: 22px;
    }

    .cart_product figure {
        width: 100px;
        height: 100px;
    }

    .cart_product .product_info {
        margin: 0 15px;
    }

    .filter {
        position: fixed;
        background-color: #fff !important;
        bottom: -50%;
        width: 100%;
        height: 90%;
        overflow-y: scroll;
        display: none;
        z-index: 9999;
        right: 0;
        transition: all .3s ease-in-out;
        box-shadow: -2px 0px 8px #a8a8a8;
    }

        .filter.show {
            display: block;
            opacity: 1;
            bottom: 0;
        }

    .navbar {
        border-bottom: 1px solid #e1e1e1;
    }

    .over-lay {
        position: fixed;
        z-index: 9800;
        background-color: #00000070;
        top: 0;
        backdrop-filter: blur(2px);
        right: 0;
        transition: all .3s ease-in-out;
        width: 100%;
        height: 100%;
    }

    .login-form {
        padding: 20px 25px;
    }

    .login_svg {
        width: 100% !important;
    }

    .login .row {
        height: 100%;
    }

    .login {
        width: 100%;
    }

    .ordr_placed h5 {
        font-size: 14px;
    }

    .ordr_placed button {
        white-space: nowrap;
        margin-bottom: 10px;
    }

    .react-stars span {
        font-size: 25px !important;
    }

    .rating .rateValue {
        transform: translate(-50%, 15px);
        font-size: 14px;
    }

        .rating .rateValue .ratingtxt {
            font-size: 16px;
        }

    .address_tab .customerName {
        font-size: 18px !important;
    }

    .address_tab .tab_body p {
        font-size: 14px;
    }

    .wishlist img {
        width: 100%;
    }

    .empty_cart img {
        width: 100%;
    }

    .cart_product {
        width: 100%;
        display: block;
    }

        .cart_product .product_info {
            display: block;
        }

        .cart_product .cart_price {
            margin-top: 10px;
        }

    .featured_products .product_list {
        flex-wrap: nowrap;
        overflow-x: scroll;
    }

        .featured_products .product_list .product_item {
            width: 60% !important;
        }

    .featured_products .slick-slide, .also_like .slick-slide {
        width: 280px !important;
    }

    .featured_products .product_list::-webkit-scrollbar {
        display: none !important;
    }

    .pd_info .head {
        font-size: 22px;
        font-weight: 600;
    }

    .cart_card .productName {
        width: 100% !important;
    }

    .product_gallary .thumb_image img {
        width: 100%;
        height: 100%;
        border: 1px solid #ebebeb;
        padding: 5px;
    }

    .shopbydisscount .discountscroll {
        flex-wrap: nowrap;
        overflow: scroll;
    }

        .shopbydisscount .discountscroll::-webkit-scrollbar {
            display: none;
        }

    .discount_card span:nth-of-type(1) {
        font-size: 16px;
    }

    .discount_card span:nth-of-type(2) {
        font-size: 52px;
        line-height: 46px;
    }

    .discount_card span:nth-of-type(3) {
        font-size: 12px;
        padding: 1px 8px;
    }

    .discount_card h4 {
        font-size: 18px;
    }
}

@media only screen and (max-width: 1000px) {
    .featured_brands .row .col-md-3 {
        width: 55%;
    }

    .featured_brands .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: scroll;
    }

        .featured_brands .row::-webkit-scrollbar {
            display: none;
        }
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
    .discount_card span:nth-of-type(1) {
        font-size: 16px;
    }

    .discount_card span:nth-of-type(2) {
        font-size: 52px;
        line-height: 46px;
    }

    .discount_card span:nth-of-type(3) {
        font-size: 12px;
        padding: 1px 8px;
    }

    .discount_card h4 {
        font-size: 18px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .login .row {
        height: 590px;
    }

    .login {
        margin: 0 auto;
        width: 100%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
    .navbar-brand img {
        width: 120px;
        padding: 0px
    }
}

.MuiSkeleton-root {
    transform: inherit !important;
}

.mypagination .Mui-selected {
    background-color: var(--primary) !important;
    color: #fff !important;
}

.mypagination .MuiPaginationItem-root {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 15px 64px 0px;
}

.pulse {
    animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px #045f2d0d;
    }

    100% {
        box-shadow: 0 0 0 10px #03a84e0d;
    }
}


.stores_list {
    width: 100%;
    background-color: #fff;
    padding: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 2px solid rgb(248, 248, 248);
    transition: .3s ease-in-out;
}


.storeTextWithImage {
    position: relative;
    border-radius: 1rem;
    overflow: hidden;
}

.storesNameFooter {
    position: absolute;
    bottom: 0;
    background-color: #998d8d8f;
    color: white;
    display: block;
    padding: 5px 20px;
    width: 100%;
    font-size: 32px;
    backdrop-filter: blur(2px);
}

.product_full_view {
    width: 100%;
    height: 65vh;
}

    .product_full_view img {
        width: 100%;
        height: 65vh;
        object-fit: contain;
    }

.required-products-container {
    background-color: #f2f2f2;
    padding: 10px;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 5px
}

.requiredFig {
    width: 85px;
    height: 102px;
    overflow: hidden;
    margin: 0 auto;
}

    .requiredFig img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }



.custom_open_link {
    color: #282c3f;
    line-height: 23px !important;
    font-weight: 400;
    display: block;
    font-size: 14px;
    transition: all .3s ease;
    padding: 0;
    cursor: pointer;
}

    .custom_open_link:hover {
        padding-left: 5px;
        color: #28a892;
    }



review_seemore_txt {
    padding: 0px 3px;
    color: #282c3f;
    line-height: 23px !important;
    font-weight: 400;
    display: block;
    font-size: 14px;
    transition: all .3s ease;
    cursor: pointer;
}

    review_seemore_txt:hover {
        padding-left: 5px;
        color: #28a892;
    }


.dot-loader {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: var(--primary);
    position: relative;
    -webkit-animation: 1.2s grow ease-in-out infinite;
    animation: 1.2s grow ease-in-out infinite;
}

.dot-loader--2 {
    -webkit-animation: 1.2s grow ease-in-out infinite 0.15555s;
    animation: 1.2s grow ease-in-out infinite 0.15555s;
    margin: 0 10px;
}

.dot-loader--3 {
    -webkit-animation: 1.2s grow ease-in-out infinite 0.3s;
    animation: 1.2s grow ease-in-out infinite 0.3s;
}

@-webkit-keyframes grow {

    0%, 40%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes grow {

    0%, 40%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}


.orderStatusDelivered {
    color: #198754;
}

.orderStatusCancel {
    color: #DC3545;
}

.rolldown-list {
    transform-origin: 50% 0;
    animation-fill-mode: forwards;
    background: #f4f4f4;
    margin-bottom: 5px;
    border-radius: 3px;
    transition: all .3s ease;
}

.cat_head {
    font-weight: 600;
}

.cat_link {
    font-size: 14px;
}

    .cat_link:hover {
        color: var(--primary)
    }

/* @keyframes rolldown {
    0% {
        visibility: visible;
        transform: rotateX(180deg) perspective(500px);
    }

    70% {
        visibility: visible;
        transform: rotateX(-20deg);
    }

    100% {
        visibility: visible;
        transform: rotateX(0deg);
    }
} */

.orderPage_heading {
    cursor: pointer;
    max-width: 600px;
}

    .orderPage_heading:hover {
        color: #28a892;
    }


.search_input {
    width: 100%;
    font-size: 15px !important;
    border: 0px;
    border-color: #28a892;
    background-color: transparent;
    outline: none;
    transition: border-color 0.2s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

    .search_input:focus {
        background-color: transparent;
    }



.prduct_Category_brandName {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.footer_about .accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
    transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:not(.collapsed) {
    background-color: #e9e9e9 !important;
    border-radius: 30px;
}

.footer_about .accordion-button::after {
    background-image: var(--bs-accordion-btn-icon);
    background-size: var(--bs-accordion-btn-icon-width);
}

.accordion-button:focus {
    box-shadow: none;
    background-color: #f4f4f4;
}

.footer_about .accordion-button {
    font-size: 18px;
    background-color: transparent;
    color: #000;
    z-index: 2;
}

.accordion-header {
    border-radius: 30px;
    overflow: hidden;
}

.accordion-item {
    border: 0;
}

.footer_about .accordion-button::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scale(0);
    background-color: #e9e9e9;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 30px;
    transition: transform .2s cubic-bezier(.345,.115,.135,1.42),background .15s ease-out,box-shadow .2s ease-out;
}

.footer_about .accordion-button:hover:before {
    transform: scale(1)
}

.footer_about .accordion-body {
    background-color: transparent;
}

    .footer_about .accordion-body p {
        font-weight: 500;
        color: #6f6f6f;
    }

ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
}

    ol > li {
        display: table;
        counter-increment: item;
        margin-bottom: 0.6em;
    }

        ol > li:before {
            content: counters(item, ".") ". ";
            display: table-cell;
            padding-right: 0.6em;
        }

li ol > li {
    margin: 0;
}

    li ol > li:before {
        content: counters(item, ".") " ";
    }

.profile_photo {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    overflow: hidden;
}

    .profile_photo img {
        width: 100%;
        object-fit: cover;
    }

.photo_uploader label {
    border: 2px dashed var(--primary);
    border-radius: 10px;
    padding: 20px 0;
    width: 80%;
    margin: 0 auto;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profile_body {
    background-color: #f8f9fa;
    border-radius: 10px;
    margin-top: 20px;
    padding: 20px;
}

    .profile_body input, .profile_body select {
        padding: 10px;
    }

        .profile_body select:focus {
            box-shadow: none;
        }

.mytoaster {
    bottom: 20px;
    left: 50%;
    z-index: 9999;
    position: fixed;
    transform: translateX(-50%);
}

    .mytoaster .card {
        box-shadow: rgb(38 57 77 / 30%) 0px 20px 30px -10px;
        border-color: var(--primary);
        flex-direction: row;
        align-items: center;
        gap: 10px;
        max-width: 450px;
        display: flex;
    }

        .mytoaster .card svg {
            width: 40px;
        }

.path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    &.circle

{
    -webkit-animation: dash 1s ease-in-out;
    animation: dash 1s ease-in-out;
}

&.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash 1s .35s ease-in-out forwards;
    animation: dash 1s .35s ease-in-out forwards;
}

&.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check 1s .35s ease-in-out forwards;
    animation: dash-check 1s .35s ease-in-out forwards;
}

}


@-webkit-keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@-webkit-keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }

    100% {
        stroke-dashoffset: 900;
    }
}

@keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }

    100% {
        stroke-dashoffset: 900;
    }
}

.MuiSelect-standard {
    display: flex !important;
}

.css-tlelie-MuiListItemText-root {
    flex: 1 1 auto;
    min-width: 0;
    margin-top: 4px;
    margin-bottom: 0px !important;
    margin-right: 2px !important;
}

.MuiListItemIcon-root css-cveggr-MuiListItemIcon-root {
    margin-left: 54px !important;
    min-width: none !important;
}

.css-n70jm4-MuiInputBase-root-MuiInput-root-MuiSelect-root {
    margin-top: 0px !important;
}

.css-1al598k {
    margin-top: 0px !important;
}

.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select {
    height: none !important;
}

.css-1cccqvr.MuiSelect-select {
    height: none !important;
}

.swiper-video-container {
    position: relative;
    width: 100%;
    height: 100%;
}

    .swiper-video-container iframe {
        position: absolute;
        top: 0;
        left: 100px;
        padding-bottom: 80px;
        width: 80%;
        height: 100%;
    }

.cardOrderDetails {
    border: 2px solid grey !important;
    border-radius: 10px !important;
}


.goog-te-banner-frame.skiptranslate {
    display: none !important;
}

body {
    top: 0 !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
    visibility: hidden !important;
}

#2.container {
    display: none !important;
}

.VIpgJd-ZVi9od-aZ2wEe-OiiCO VIpgJd-ZVi9od-aZ2wEe-OiiCO-ti6hGc {
    visibility: hidden !important;
}

.VIpgJd-ZVi9od-aZ2wEe {
    visibility: hidden !important;
}

.goog-te-gadget-icon {
    display: none !important;
}

.VIpgJd-ZVi9od-aZ2wEe-OiiCO-ti6hGc {
    display: none !important;
}

.css-1cccqvr.MuiSelect-select {
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
}

.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select {
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #359f8c !important;
}

.MuiInputLabel-root, .MuiInputBase-root, .MuiTypography-root, .goog-te-gadget {
    font-family: 'Inter' !important;
}

.MuiTypography-root {
    color: #666;
}

.MuiFormControl-root {
    margin-top: 10px !important;
}

.login .MuiBox-root {
    align-items: baseline !important;
}

@media (min-width: 1200px) {
    .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1200px;
    }
}

.MuiBadge-badge {
    right: 70% !important;
    top: 5px !important;
}

.share_btn {
    top: 5px;
    right: 5px;
    z-index: 999;
    background: #fff;
    border-radius: 50%;
}

    .share_btn > .dropdown {
        position: absolute;
        top: 40px;
        right: 5px;
        border-radius: 8px;
        overflow: hidden;
        width: 140px;
        height: fit-content;
        background: #fff;
        /* border: 1px solid #555; */
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    }

        .share_btn > .dropdown a {
            padding: 5px 10px;
            display: block;
            color: #555;
            border-bottom: 1px solid #c4c4c4;
        }

            .share_btn > .dropdown a:hover {
                background-color: #f4f4f4;
                color: #000;
            }

            .share_btn > .dropdown a svg {
                width: 16px;
            }
